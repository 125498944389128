<section class="pt-5 pb-0">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center">
      <div class="col-lg-10">
        <h1 class="page-bigtitle">Regulatory Disclosure</h1>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks newhomepage ">
  <div class="container">
    <div class="regulatory-disclosure">
      <!-- <h4>Regulatory Disclosure</h4> -->
      <p>
        SSBA Innovations Limited (Trade name: Finbingo.com) is a SEBI Registered Investment Advisor –
        INA000012166. <br>
        SEBI requires RIA’s to disclose complaint status on their website. Pursuant to the same, please see below-
      </p>
      <table>
        <thead>
          <tr>
            <th colspan="5">NUMBER OF COMPLAINTS AS ON <span [innerHTML]="lastMonthDate"></span></th>
          </tr>
          <tr>
            <th>At the beginning of the month</th>
            <th>Received during the month</th>
            <th>Resolved during the month</th>
            <th>Pending at the end of the month</th>
            <th>Reason for pending status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-3">As per SEBI compliance guidelines for RIA, holdings of advisor will be made available on specific
        request mailed to <a href="mailto:care@finbingo.com">care@finbingo.com</a><br>
        For any grievances/complaints, the investor or client can send an email on <a
          href="mailto:grievance@ssbainnovations.com">grievance@ssbainnovations.com</a></p>
    </div>
  </div>
</section>